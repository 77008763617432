@import "../variables.less";
@import '~slick-carousel/slick/slick.css';
// @import "~flipcountdown/jquery.flipcountdown.css";

#section0 {
    margin-top: -60px;
    padding-top: 60px;
}

.slick-slider {
    -webkit-user-select: text;
    -moz-user-select: text;
    -ms-user-select: text;
    user-select: text;
}

.promo {

    position: relative;
    height: 520px;

    background-image: url(../../img/promo_slider_bg.jpg);
    padding: 0;

    @promoHeight: 640px - 40px;
    @carouselControlHeight: 135px;
    @carouselSliderHeight: @promoHeight - @carouselControlHeight - 40px;

    .promo-nav {
        max-width: 1170px;
        margin: 0 auto;
        position: absolute;
        bottom: 150px;
    }

    .promo-carousel {
        //max-width: 1170px;
        margin: 0 auto;
    }

    @media (max-width: 1200px) {
        .promo-slide {
            .inner {
                padding: 40px 20px !important;
            }
        }
    }
    @media (max-width: 992px) {
        padding: 0;

        .promo-carousel {
            //display: none;
        }

        .promo-slide {
            .inner {
                padding: 20px 20px !important;
            }
        }

        .promo-nav {
            .promo-slide-indicators {
                &__item {
                    &.active {
                        &:after {
                            display: none;
                        }
                    }
                }
            }
        }
    }

    &.inactive {
        .promo-slide {
            display: none!important;
            &:first-of-type {
                display: flex!important;
            }
        }
    }

    .promo-slide {
        width: 100%;
        margin: 0;
        height: 520px;
        display: flex!important;
        flex-wrap: nowrap;
        background-size: cover;
        background-repeat: no-repeat;

        .inner {
            width: 1170px;
            padding: 40px 0px;
            display: flex!important;
            flex-wrap: nowrap;
            margin-left: auto;
            margin-right: auto;
            &:lang(ar) {
                direction: rtl;
            }
        }

        &__footer {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            flex-grow: 1;
            position: absolute;
            bottom: 160px;
            &:lang(ar) {
                right: 0;
            }
        }

        &__left-side {
            width: 50%;
            padding: 0;
            display: flex;
            flex-direction: column;
            color: #fff;
            position: relative;
            @media only screen and (max-width: 830px) {
                width: 90%;
                margin-right: 10px;
            }
        }
        &__logo {
            width: 30%;
            padding: 5px;
            display: flex;
            align-items: center;
            // height: @carouselSliderHeight;

            img {
                max-height: 100%;
                max-width: 100%;
                margin: 0 auto;
            }
        }
        &__right-side {
            padding: 0 0 0 30px;
            margin-left: 10%;
            width: 40%;
            &:lang(ar) {
                margin-left: initial;
                padding: 0 30px 0 0;
                margin-right: 10%;
            }
            @media only screen and (max-width: 830px) {
                display: none !important;
                width: 0;
            }
        }

        &__title {
            margin-bottom: 10px;
            font-size: 25px;
            &:lang(ar) {
                font-size: 29px;
            }
        }
        &__text {
            line-height: 1.5;
            font-size: 16px;
            &:lang(ar) {
                font-size: 20px;
            }
            height: 180px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            @media only screen and (max-width: 480px) {
                font-size: 14px;
            }
        }

        .promo-slide-features {
            display: flex;
            flex-direction: column;
            color: #fff;

            .features-content {
                display: flex;
                flex-grow: 1;
                //justify-content: center;
                flex-direction: column;
                font-size: 16px;
                color: #fff;

                .smaller {
                    letter-spacing: normal;
                    font-size: 14px;
                    line-height: 1;
                }
                .oppositeColor {
                    color: @oppositeColor;
                }
                .white-color {
                    color: #ffffff;
                }
                .features-phone {
                    color: #ffffff;
                    font-size: 24px;
                    font-weight: 700;
                }
            }

            .features-footer {
                font-weight: 400;
                font-size: 11px;
                text-transform: uppercase;
                & > * {
                    font-weight: inherit;
                    font-size: inherit;
                }
            }
        }

        .promo-slide-links {
            margin-top: 15px;

            a {
                margin-right: 20px;
            }

            &__item {
                margin-bottom: 4px;
                border-bottom: 1px dotted @alternativeColor;
                font-size: 18px;
                position: relative;
                font-weight: 400;
                color: @oppositeColor;
                text-transform: none;
                &:hover {
                    color: @alternativeColor;
                    text-decoration: none;
                }

            }
        }
    }

    .btn-primary-eggplant,
    .btn-primary-cyan {
        @media only screen and (max-width: 480px) {
            font-size: 16px;
            min-width: 140px;
        }
    }

    .promo-nav {
        position: relative;
        // height: @carouselControlHeight;

        .promo-slide-indicators {
            list-style: none;
            display: flex;
            flex-wrap: wrap;
            column-gap: 10px;
            max-width: 100%;
            padding: 10px 0;
            margin: 0;
            &:lang(ar) {
                direction: rtl;
            }

            &__item {
                flex-grow: 1;
                flex-basis: 0;
                position: relative;
                line-height: 1.2;
                background-color: @darkBlue;
                display: flex;

                &:hover {
                    background-color: @cyan;
                }
                a {
                    display: flex;
                    align-items: center;
                    height: 75px;
                    &:lang(ar) {
                        height: 80px;
                    }
                    width: 100%;
                    //background-color: @darkBlue;
                    //padding: 0 10px 0 12px;
                    padding: 0 10px;
                    box-sizing: border-box;
                    color: white;
                    font-size: 13px;
                    // transition: background-color .25s ease-out;
                    i {
                        margin-right: 10px;
                    }

                    @media (max-width: 910px) {
                        font-size: 0;
                        padding: 0;
                        i {
                            margin: auto;
                        }
                    }
                }

                @media (min-width: 992px) {
                    &.active {
                        background-color: @cyan;
                    }
                }


            }

            &__item:first-child:nth-last-child(6),
            &__item:first-child:nth-last-child(6) ~ li
            {
                a {
                    padding: 0 10px 0 15px;
                    font-size: 13px;
                    @media (max-width: 980px) {
                        font-size: 0px;
                        padding: 0;
                        justify-content: center;
                        i {
                            margin: auto;
                        }
                    }
                }
            }
        }
    }

    &-banner {
        width: 100%;
        padding: 8px;
        font-size: 16px;
        color: @eggplant;

        .bold {font-weight:bold;}
        a {text-decoration: underline !important;}
        a > .glyphicon {margin-right:5px;}
        a:hover > .download-text {border-bottom:1px dotted #fff}
        p {
            margin: 0!important;
        }
    }

    .slick-dots {
        position: absolute;
        bottom: 35px;
        display: block;
        width: 100%;
        padding: 0;
        margin: 0;
        list-style: none;
        text-align: center;
        &:lang(ar) {
            direction: rtl;
        }

        li {
            position: relative;
            display: inline-block;
            width: 20px;
            height: 20px;
            margin: 0 20px;
            padding: 0;
            cursor: pointer;

            button
            {
                font-size: 0;
                line-height: 0;
                display: block;
                width: 20px;
                height: 20px;
                padding: 5px;
                cursor: pointer;
                color: transparent;
                border: 0;
                outline: none;
                background: transparent;

                &:before
                {
                    font-family: 'slick';
                    font-size: 90px;
                    line-height: 20px;
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 20px;
                    height: 20px;
                    content: '•';
                    text-align: center;
                    color: #fff;
                    -webkit-font-smoothing: antialiased;
                    -moz-osx-font-smoothing: grayscale;
                }
            }

            &.slick-active {
                button {
                    &:before {
                        color: @cyan;
                    }
                }
            }
        }
    }

    @media only screen and (max-width: 450px) {
        display: initial;

        .promo-carousel {
            visibility: hidden;
            height: 0;
        }
        .promo-nav {
            position: initial;
            bottom: initial;
            top: initial;

            .promo-slide-indicators {
                flex-direction: column;
                row-gap: 10px;
                padding: 10px;
                &__item {
                    margin: 0;
                    padding: 0 20px !important;
                    a
                    {
                        font-size: 14px !important;
                        column-gap: 20px;
                        i {
                            margin: 0;
                        }
                    }
                }
            }
        }
    }
    .index-slider-arrow {
        display: none;
    }
}

.clients-items {
    &:lang(ar) {
        float:right;
    }
}
.clients-item .img-wraps {
    height: 70px;
    margin-bottom: 2px;
    img {
        max-height: 70px;
    }
}

.utility-va-mid {
    vertical-align: middle;
}
